import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/Login',
		name: 'Login',
		component: () => import('@/views/Login')
	},
	{
		path: '/FindPass',
		name: 'FindPass',
		component: () => import('@/views/FindPass')
	},
	{
		path: '/Stats/Rankings',
		name: 'Stats.Rankings',
		component: () => import('@/views/web/Stats/Rankings')
	},
	{
		path: '/Stats/Activity',
		name: 'Stats.Activity',
		component: () => import('@/views/web/Stats/Activity')
	},
	{
		path: '/Explore/Explore/:type',
		name: 'Explore.Explore',
		component: () => import('@/views/web/Explore/Explore')
	},
	{
		path: '/Explore/Collection/:cnId',
		name: 'Explore.Collection',
		component: () => import('@/views/web/Explore/Collection')
	},
	{
		path: '/Content/Content/:pnId/:pnType/',
		name: 'Content.Content',
		component: () => import('@/views/web/Content/Content')
	},
	{
		path: '/Content/SubContent/:pnId/',
		name: 'Content.SubContent',
		component: () => import('@/views/web/Content/SubContent')
	},
	{
		path: '/Detail/Detail/:cnId',
		name: 'Detail.Detail',
		component: () => import('@/views/web/Detail/Detail')
	},
	{
		path: '/Create/:pnId',
		name: 'Create',
		component: () => import('@/views/web/Create/Create'),
	},
	{
		path: '/Message',
		name: 'Message',
		component: () => import('@/views/web/Message/Message'),
	},
	{
		path: '/Material/:type',
		name: 'Material',
		component: () => import('@/views/web/Material/Material'),
	},
	{
		path: '/Order/:pnId',
		name: 'Order',
		component: () => import('@/views/web/Order/Order'),
	},
	{
		path: '/about',
		name: 'About',
		component: () => import('@/views/About'),
	},
	{
		path: '/Admin',
		name: 'Admin',
		component: () => import('@/views/Admin/Admin'),
		children:[
			// {
			// 	path: '/Admin/Address',
			// 	name: 'Admin.Address',
			// 	component: () => import('@/views/Admin/Address/Address'),
			// },
			// {
			// 	path: '/Main/TopUp',
			// 	name: 'Main.TopUp',
			// 	component: () => import('@/views/UserCenter/TopUp/TopUp'),
			// },
			// {
			// 	path: '/Main/Withdraw',
			// 	name: 'Main.Withdraw',
			// 	component: () => import('@/views/UserCenter/Withdraw/Withdraw'),
			// },
		]
	},
	{
		path: '/Main',
		name: 'Main',
		component: () => import('@/views/UserCenter/Main'),
		children:[
			{
				path: '/Main/User',
				name: 'Main.User',
				component: () => import('@/views/UserCenter/User/User'),
			},
			{
				path: '/Main/TopUp',
				name: 'Main.TopUp',
				component: () => import('@/views/UserCenter/TopUp/TopUp'),
			},
			{
				path: '/Main/Withdraw',
				name: 'Main.Withdraw',
				component: () => import('@/views/UserCenter/Withdraw/Withdraw'),
			},
		]
	},
	{//他人个人中心
		path: '/OthersMain',
		name: 'OthersMain',
		component: () => import('@/views/OthersCenter/Main'),
		children:[
			{
				path: '/OthersMain/User/:urId/:urName',
				name: 'OthersMain.User',
				component: () => import('@/views/OthersCenter/User/User'),
			}
		]
	},
	{
		path: '/Setting',
		name: 'Setting',
		component: () => import('@/views/Setting/Setting'),
		children:[
			{
				path: '/Setting/Basic',
				name: 'Setting.Basic',
				component: () => import('@/views/Setting/Basic/Basic'),
			},
			{
				path: '/Setting/Give',
				name: 'Setting.Give',
				component: () => import('@/views/Setting/Give/Give'),
			},
			{
				path: '/Setting/Address',
				name: 'Setting.Address',
				component: () => import('@/views/Setting/Address/Address'),
			},
			{
				path: '/Setting/Bank',
				name: 'Setting.Bank',
				component: () => import('@/views/Setting/Bank/Bank'),
			},
			{
				path: '/Setting/Secure',
				name: 'Setting.Secure',
				component: () => import('@/views/Setting/Secure/Secure'),
				children:[
					{
						path: '/Setting/Secure/LoginPass',
						name: 'Setting.Secure.LoginPass',
						component: () => import('@/views/Setting/Secure/LoginPass/LoginPass'),
					},
					{
						path: '/Setting/Secure/PayPass',
						name: 'Setting.Secure.PayPass',
						component: () => import('@/views/Setting/Secure/PayPass/PayPass'),
					},
				]
			},
		]
	}
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});
export default router
