<template>
  <router-view v-if="isReload"/>
</template>
<script>
export default {
  name: "app",
  data() {
    return {
      isReload: true
    }
  },
  provide() {
    return {
      reload: this.reload,
    }
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    reload() {
      this.isReload = false;
      this.$nextTick(() => {
        this.isReload = true;
      });
    }
  },
  mounted() {
    console.log('mounted')
    if(this.isMobile()){
      console.log('isMobile')

      window.location.href = location.origin + '/h5/'
    }
  }
};
</script>
<style>
body {
  height: 100%;
}

body, p, h1, h3, h5, h6, ul {
  margin: 0px;
  padding: 0px;
  cursor: pointer;
}



#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.el-input-group__append {
  background: #409eff !important;
  color: #fff !important;
}

.dialog .nc_scale {
  height: 50px !important;
}

.dialog .nc-container .nc_scale span {
  width: 48px !important;
  height: 48px !important;
  line-height: 48px !important;
}

.dialog .el-dialog__body {
  height: 50px !important;
}

.dialog .nc-container .scale_text.scale_text span[data-nc-lang="SLIDE"] {
  width: 150px !important;
}

.dialog .el-dialog__header {
  padding: var(--el-dialog-padding-primary);
}

.subMenu .el-icon svg {
  width: 0em !important;
  height: 0em !important;
}

.el-sub-menu__title, .el-menu-item {
  color: rgb(4, 17, 29, 0.75) !important;
  font-size: 16px !important;
  font-weight: 600;
}

.menuItem {
  width: 100% !important;
  height: 54px !important;
  padding: 15px !important;
}

.menuItem:hover {
  box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
}

.el-menu--popup {
  padding: 0px !important;
}

.el-menu--popup > :not(:last-child) {
  border-bottom: 1px solid rgb(229, 232, 235);
}

.el-scrollbar {
  border-bottom: 10px;
}

.el-dropdown__popper {
  border-radius: 10px;
}

.el-dropdown-menu {
  padding: 0px !important;
}

.el-dropdown-menu__item {
  /* width:220px; */
  height: 39px;
  border-bottom: 1px solid rgb(229, 232, 235);
  font-weight: bold;
}

.el-dropdown-menu__item:hover {
  box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
}

.el-collapse-item__header.is-active {
  border-bottom: 1px solid #ebeef5 !important;
}

.el-collapse-item__header {
  padding: 10px 15px;
  font-weight: bold !important;
  font-size: 16px !important;
}

.el-collapse-item__content {
  padding: 15px;
}

.el-collapse-item__arrow {
  transform: rotate(90deg) !important;
  font-size: 18px !important;
  font-weight: bold;
}

.el-collapse-item__arrow.is-active {
  font-size: 18px;
  font-weight: bold;
  transform: rotate(-90deg) !important;
}

.boxListCon .el-table--enable-row-transition .el-table__body td.el-table__cell {
  border-bottom: none !important;
  padding: 15px 0px;
}

.boxListCon .el-table__inner-wrapper::before {
  background: none !important;
}

.boxListCon .el-table .el-table__cell {
  padding: 25px 0px;
}

.boxListCon .el-table th.el-table__cell > .cell {
  color: #000;
  font-size: 16px;
}

.searchBox .el-input__inner {
  height: 40px !important;
}

.el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title {
  border-bottom: none !important;
}

a {
  text-decoration: none !important;
  color: #000;
}

li {
  list-style-type: none;
}

.loginBox .el-input__inner {
  height: 40px !important;
}

#nc_1__scale_text {
  background: #409eff !important;
  /* 默认背景色 */
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-dialog__footer {
  padding: 10px !important;
  border-top: 1px solid #d9d9d9;
}

.el-dialog__header {
  padding: 10px !important;
  border-bottom: 1px solid #d9d9d9;
  margin-right: 0px;
}

.el-drawer__header {
  margin-bottom: 0px !important;
  padding-bottom: 20px;
  border-bottom: 1px solid #d9d9d9 !important;
}

.el-input__inner {
  height: 40px;
}

.el-drawer__body {
  padding-top: 0px !important;
}

.card-carousel-box .el-image__inner {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.dialogSty .el-dialog__body {
  height: 350px !important;
  padding-top: 15px !important;
}

.dialogSty .el-input__inner {
  padding-left: 35px !important;
}

.el-checkbox__label {
  font-size: 16px !important;
  font-weight: bold !important;;
}

.dialogSell .el-dialog__body {
  padding-top: 15px !important;
}

.dialogSell .el-input__inner {
  padding-left: 35px !important;
}

.wallet .el-icon {
  color: rgb(45, 129, 226);
  font-size: 30px;
}

.totalAmount {
  text-align: right;
}

.User .el-table tr {
  background: rgb(243, 243, 243) !important;
}

.User .el-table th.el-table__cell {
  background: rgb(243, 243, 243) !important;
}

.addressList .el-radio__label {
  display: none !important;
}

.is-active .el-carousel__button {
  background: rgb(32, 129, 226) !important;
}
</style>
