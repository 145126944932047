<template>
  <div class="footer">
    <div class="content">
      <el-image src="/img/logoTop.png" :fit="'contain'" style="width: 260px"></el-image>
      <div class="bottom">
        <div class="text">
          Copyright © 2022 艺集星链（杭州）科技有限公司
        </div>
          <a href="https://beian.miit.gov.cn" target="_blank" class="text">浙ICP备2022024596号-1</a>
        <div class="text">
          浙江省杭州市拱墅区浙窑文创园
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  create() {

  },
  methods: {}
};
</script>
<style scoped>
.footer {
  width: 100%;
  /*height: 80px;*/
  background: rgb(32, 129, 226);
  padding: 10px 0;
}

.content {
  width: 1280px;
  margin: auto;
  /*display: flex;*/
  /*align-items: flex-end;*/
}

.bottom{
  margin-top: 5px;
  display: flex;
}

.content .text{
  color: white;
  margin-left: 20px;
  font-size: 14px;
}
a {
  text-decoration: none; /* 去除默认的下划线 */
}
</style>
