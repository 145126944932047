<template>
	<div class="homePage">
		<Header></Header>
		<div class="main">
			<div class="mainBox">
				<div class="mainBoxTop">
					<el-carousel height="500px">
					  <el-carousel-item>
						<img src="../assets/img/22.jpg">
					  </el-carousel-item>
<!--					  <el-carousel-item>-->
<!--					  	<img src="../assets/img/33.jpg">-->
<!--					  </el-carousel-item>-->
					  <el-carousel-item>
					  	<img src="../assets/img/6.webp">
					  </el-carousel-item>
					</el-carousel>
				</div>
<!--				<div class="mainCollections">-->
<!--					<div class="text">-->
<!--						<span class="ii">顶级收藏</span>-->
<!--						<span class="tt">-->
<!--							<el-dropdown trigger="click" @command="handleCommand">-->
<!--							    <span class="el-dropdown-link">-->
<!--							      {{typeName}}-->
<!--							      <el-icon :size="18" class="el-icon&#45;&#45;right">-->
<!--							        <ArrowDown />-->
<!--							      </el-icon>-->
<!--							    </span>-->
<!--							    <template #dropdown>-->
<!--									<el-dropdown-menu>-->
<!--									  <el-dropdown-item command="0_最近24小时">最近24小时</el-dropdown-item>-->
<!--									  <el-dropdown-item command="1_最近7天">最近7天</el-dropdown-item>-->
<!--									  <el-dropdown-item command="2_最近30天">最近30天</el-dropdown-item>-->
<!--									  <el-dropdown-item command="3_所有时间">所有时间</el-dropdown-item>-->
<!--									</el-dropdown-menu>-->
<!--							    </template>-->
<!--							  </el-dropdown>-->
<!--						</span>-->
<!--					</div>-->
<!--					<div class="mainCollectionsBox">-->
<!--						<el-row :gutter="40">-->
<!--						    <el-col :span="8" v-for="(item,index) in rows" :key="index">-->
<!--								<div class="con" @click="ExploreBtn(item.cnId)">-->
<!--									<div class="num">{{item.numb}}</div>-->
<!--									<div class="user">-->
<!--										<div class="img">-->
<!--											<img :src="item.cnLogo">-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="name">-->
<!--										<div class="ii">{{item.cnName}}</div>-->
<!--										<div class="tt">-->
<!--											<span v-if="Number(item.floorPrice)==0">底价:-&#45;&#45;</span>-->
<!--											<span v-else-if="Number(item.floorPrice)>0">底价:￥{{item.floorPrice}}</span>-->
<!--											<span v-else style="color:red;font-weight: bold;">底价:￥{{item.floorPrice}}</span>-->
<!--										</div>-->
<!--									</div>-->
<!--									<div class="count">-->
<!--										<div class="ii" v-if="Number(item.lift24)==0">-&#45;&#45;</div>-->
<!--										<div class="ii" v-else-if="Number(item.lift24)>0">+{{item.lift24}}%</div>-->
<!--										<div class="ii" style="color:red;" v-else>{{item.lift24}}%</div>-->
<!--										<div class="tt">-->
<!--											<span>交易额:</span>-->
<!--											<span>￥{{item.gmv}}</span>-->
<!--										</div>-->
<!--									</div>-->
<!--								</div>-->
<!--							</el-col>-->
<!--						</el-row>-->
<!--						<div class="goTo" @click="rankingBtn">-->
<!--							<el-button type="primary">去看排名</el-button>-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->
				<div class="mainCategorie">
					<div class="text">
						<span class="ii">火爆专辑</span>
						<span class="tt">
							<el-dropdown trigger="click" @command="handleCommandSort">
							    <span class="el-dropdown-link">
							      {{cteName}}
							      <el-icon :size="18" class="el-icon--right">
							        <ArrowDown />
							      </el-icon>
							    </span>
							    <template #dropdown>
									<el-dropdown-menu>
										<el-dropdown-item command="_所有类别">所有类别</el-dropdown-item>
										<el-dropdown-item v-for="item in cteIdRows" :command="item.cteId+'_'+item.cteName">{{item.cteName}}</el-dropdown-item>
									</el-dropdown-menu>
							    </template>
							  </el-dropdown>
						</span>
					</div>
					<Carousel :cteId="cteId" type="0"></Carousel>
				</div>
				<div class="mainCategorie">
					<div class="text">
						<span class="ii">热门单品</span>
						<span class="tt">
							<el-dropdown trigger="click" @command="handleCommandSort">
							    <span class="el-dropdown-link">
							      {{cteName}}
							      <el-icon :size="18" class="el-icon--right">
							        <ArrowDown />
							      </el-icon>
							    </span>
							    <template #dropdown>
									<el-dropdown-menu>
										<el-dropdown-item command="_所有类别">所有类别</el-dropdown-item>
										<el-dropdown-item v-for="item in cteIdRows" :command="item.cteId+'_'+item.cteName">{{item.cteName}}</el-dropdown-item>
									</el-dropdown-menu>
							    </template>
							  </el-dropdown>
						</span>
					</div>
					<Carousel :cteId="cteId" type="1"></Carousel>
				</div>
				<div class="mainBrowse">
					<div class="text">分类浏览</div>
				</div>
				<div class="typeBox">
					<el-row :gutter="20">
						<el-col :span="8" v-for="(item,index) in classRows" :key="item.cteId">
							<div class="box">
								<img :src="item.cteIcon">
								<div class="text">{{item.cteName}}</div>
							</div>
						</el-col>
					</el-row>
				</div>
<!--				<div class="mainCreate">-->
<!--					<div class="text">创建并销售您的作品</div>-->
<!--					<el-row :gutter="20">-->
<!--						<el-col :span="6">-->
<!--							<div class="boxList">-->
<!--								<div class="img">-->
<!--									<img src="../assets/img/pass.png">-->
<!--								</div>-->
<!--								<div class="title">设置你的交易密码</div>-->
<!--								<div class="con">-->
<!--									登录您的账号，进入设置页面，找到安全，交易密码选项，设置您的交易密码。交易密码是您购买藏品及铸造作品的重要凭证，请谨慎设置并妥善保管您的交易密码信息。-->
<!--								</div>-->
<!--							</div>-->
<!--						</el-col>-->
<!--						<el-col :span="6">-->
<!--							<div class="boxList">-->
<!--								<div class="img">-->
<!--									<img src="../assets/img/zj.png">-->
<!--								</div>-->
<!--								<div class="title">创建你的专辑</div>-->
<!--								<div class="con">-->
<!--									进入个人中心，我的专辑选项，点击创建，上传logo、封面图、横幅，填写专辑名称、简介及外链，即可创建您的专辑。-->
<!--									每个专辑都有独立的首页，好的封面图和横幅，将大大提高您专辑首页的美观度。-->
<!--								</div>-->
<!--							</div>-->
<!--						</el-col>-->
<!--						<el-col :span="6">-->
<!--							<div class="boxList">-->
<!--								<div class="img">-->
<!--									<img src="../assets/img/zp.png">-->
<!--								</div>-->
<!--								<div class="title">铸造作品</div>-->
<!--								<div class="con">-->
<!--									点击创建，上传你的作品文件(图像、视频、音频或3D图像)，封面，填写作品名称、简介，点击铸造，即可铸造您的作品。-->
<!--									铸造后，您的作品将永久保存在链上，并记录下你的铸造过程。-->
<!--								</div>-->
<!--							</div>-->
<!--						</el-col>-->
<!--						<el-col :span="6">-->
<!--							<div class="boxList">-->
<!--								<div class="img">-->
<!--									<img src="../assets/img/cs.png">-->
<!--								</div>-->
<!--								<div class="title">上架出售</div>-->
<!--								<div class="con">-->
<!--									在个人中心，我创建的选项中，可以看到您铸造的所有作品，将鼠标移到作品右上角操作按钮，点击出售，设置您的作品价格，点击确认出售后，所有人就可以在市场中浏览和购买您出售的作品。-->
<!--								</div>-->
<!--							</div>-->
<!--						</el-col>-->
<!--					</el-row>-->
<!--				</div>-->
				<div class="mainResources">
					<div class="text">参考资料</div>
					<el-row :gutter="20">
					    <el-col :span="8">
							<div class="boxList" @click="Material(1)">
								<div class="con">
									<div class="img">
										<img src="../assets/img/qkl.jpeg">
									</div>
									<div class="name">什么是区块链？</div>
								</div>
							</div>
						</el-col>
						<el-col :span="8" @click="Material(2)">
							<div class="boxList">
								<div class="con">
									<div class="img">
										<img src="../assets/img/NFT.jpeg">
									</div>
									<div class="name">什么是NFT?</div>
								</div>
							</div>
						</el-col>
						<el-col :span="8" @click="Material(3)">
							<div class="boxList">
								<div class="con">
									<div class="img">
										<img src="../assets/img/YFZ.jpeg">
									</div>
									<div class="name">元宇宙</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import imgUrl from "../assets/img/unnamed.png"
	import Header from "../components/Header"
	import Footer from "../components/Footer"
	import Carousel from "../components/Carousel"
	import { VideoPlay,Warning,ArrowDown,Brush } from '@element-plus/icons-vue'
	export default {
		components: { Header,Footer,Carousel,VideoPlay,Warning,ArrowDown,Brush },
		data(){
			return{
				imgUrl: imgUrl,
				type:'0',
				typeName:'最近24小时',
				rows:[],
				cteIdRows:[],
				dataRoot:[],
				classRows:[],
				cteId:'',
				cteName:'所有类别'
			}
		},
		created() {
			let that = this;
			this.getListCollect();
			this.getListType();
			this.getListClass();
		},
		methods: {
			collectBtn(index){
				this.$router.push({
					path:'/Explore/Explore/'+ index,
				})
			},
			Material(index){
				this.$router.push({
					path:'/Material/'+ index,
				})
			},
			rankingBtn(){
				this.$router.push({
					name:'Stats.Rankings'
				})
			},
			getListClass(){
				let that = this;
				this.$axios({
					method:'post',
					url:'nft/cte/getAll',
					headers: {
						token:""
					},
					data:this.$qs.stringify({
						
					})
				})
				.then((res)=>{
					this.classRows = res.data.dataRoot;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getListType(){
				let that = this;
				this.$axios({
					method:'post',
					url:'nft/cte/getAll',
					headers: {
						token:""
					},
					data:this.$qs.stringify({
						
					})
				})
				.then((res)=>{
					this.cteIdRows = res.data.dataRoot;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getListCollect(){
				let that = this;
				let type = that.type;
				let url = "";
				if(type =='0'){
					url = "stats/ho/getList";
				}
				if(type =='1'){
					url = "stats/wk/getList";
				}
				if(type =='2'){
					url = "stats/mh/getList";
				}
				if(type =='3'){
					url = "stats/ct/getList";
				}
				this.$axios({
					method:'post',
					headers: {
						token: ""
					},
					url: url,
					data:this.$qs.stringify({
						cteId: '',
						page: 1,
						limit: 15
					})
				})
				.then((res)=>{
					let rows = res.data.dataRoot;
					rows.forEach((item)=>{
						let items = item.items;
						let gmv = item.gmv;
						if(Number(items) > 1000){
							item.isItems = (items / 1000).toFixed(2) + "k";
						}else{
							item.isItems = items;
						}
						if(Number(gmv) > 1000){
							item.gmv = (gmv / 1000).toFixed(2) + "k";
						}
					})
					that.rows = rows;
					that.total = res.data.totalCounts;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			handleCommand(command){
				this.type = command.split("_")[0];
				this.typeName = command.split("_")[1];
				this.getListCollect();
			},
			handleCommandSort(command){
				this.cteId = command.split("_")[0];
				this.cteName = command.split("_")[1];
			},
			ExploreBtn(cnId){
				this.$router.push({
					path:'/Explore/Collection/'+ cnId,
				})
			}
		},
	}
</script>
<style scoped>
	.typeBox{
		width:1280px;
		margin:0px auto;
	}
	.typeBox .box{
		height:278px;
		border:1px solid rgb(229, 232, 235);
		border-radius: 15px;
		margin-bottom:25px;
	}
	.typeBox .box img{
		width:100%;
		height:220px;
		object-fit: fill;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
	}
	.typeBox .box .text{
		height:60px;
		line-height: 60px;
		font-size:20px;
		font-weight:bold;
		text-align: center;
	}
	.mainBrowse{
		width: 100%;
		padding:40px;
		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;
		box-sizing: border-box;
	}
	.mainBrowse .text{
		text-align: center;
		font-size: 24px;
		margin-bottom: 55px;
	}
	.mainResources{
		width: 100%;
		padding:40px;
		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;
		box-sizing: border-box;
	}
	.mainResources .text{
		text-align: center;
		font-size: 24px;
		margin-bottom: 55px;
	}
	.mainResources .boxList .con{
		display: inline-block;
		background-color: rgb(255, 255, 255);
		border: 1px solid rgb(229, 232, 235);
		border-radius: 10px;
	}
	.mainResources .boxList .con .img{
		width:100%;
		height:265px;
		border-radius: inherit inherit;
		border-bottom: 1px solid rgb(229, 232, 235);
	}
	.mainResources .boxList .con .img img{
		width:100%;
		height:265px;
		object-fit: cover;
	}
	.mainResources .boxList .con .name{
		padding:20px 30px 20px 20px;
		color: rgb(53, 56, 64);
		font-weight: 600;
		font-size: 16px;
		height: 50px;
		line-height: 50px;
		text-align: center;
	}
	.mainResources .boxList .con:hover{
		animation: animated-border 0.5s infinite;
		animation-iteration-count:1;
		animation-fill-mode:forwards;
	}
	@keyframes animated-border {
		0% {
			box-shadow: 0 0 0 0 #bfbfbf;
		}
		100% {
			box-shadow: 0px 1px 23px 10px #cecece;
		}
	}
	.mainCreate{
		width: 100%;
		padding:40px;
		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;
		box-sizing: border-box;
	}
	.mainCreate .text{
		text-align: center;
		font-size: 24px;
		margin-bottom: 55px;
	}
	.mainCreate .boxList{
		height:250px;
		padding:0px 20px;
	}
	.mainCreate .boxList .img{
		text-align: center;
	}
	.mainCreate .title{
		text-align: center;
		font-weight: 600;
		font-size: 16px;
		color: rgb(4, 17, 29);
		margin-top:20px;
	}
	.mainCreate .con{
		font-weight: 400;
		font-size: 16px;
		color: rgb(112, 122, 131);
		margin-top: 15px;
		line-height: 25px;
	}
	
	.mainCategorie{
		width: 100%;
		padding:40px;
		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;
		box-sizing: border-box;
	} 
	.mainCategorie .text{
		font-size:24px;
		text-align: center;
		margin-bottom: 55px;
	}
	.mainCategorie .text .ii{
		font-size:24px;
	}
	.mainCategorie .text .tt{
		display: inline-flex;
	}
	.goTo{
		margin-top:40px;
		text-align: center;
	}
	.goTo .el-button{
		height:50px;
		font-size:16px;
		font-weight:bold;
		padding:10px 20px;
		border-radius:10px;
	}
	.mainCollections{
		width: 100%;
		padding:40px;
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
	}
	.mainCollections .text{
		font-size:24px;
		text-align: center;
		margin-bottom: 55px;
	}
	.mainCollections .text .ii{
		font-size:24px;
	}
	.mainCollections .text .tt{
		display: inline-flex;
	}
	.mainCollectionsBox .con{
		padding: 10px;
		border-bottom: 1px solid rgb(229, 232, 235);
	}
	.mainCollectionsBox .con:hover{
		border-bottom:none;
		box-shadow: 0px 1px 10px 5px #cecece;
		border-radius: 10px;
	}
	.mainCollectionsBox .con>div{
		display:inline-block;
	}
	.mainCollectionsBox .con .num{
		height:50px;
		line-height: 50px;
		margin-right: 8px;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: center;
		justify-content: center;
		flex-direction: column;
		font-weight: bold;
		vertical-align: text-bottom;
	}
	.mainCollectionsBox .con .user{
		width:52px;
		position: relative;
		margin-right:8px;
	}
	.mainCollectionsBox .con .name{
		width:152px;
		margin-right:8px;
		line-height:25px;
		vertical-align: super;
	}
	.mainCollectionsBox .con .name .ii{
		font-size:14px;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		font-weight:bold;
	}
	.mainCollectionsBox .con .name .tt{
		color: rgb(112, 122, 131);
		font-size:12px;
	}
	.mainCollectionsBox .con .count{
		line-height:25px;
		vertical-align: super;
		text-align:right;
	}
	.mainCollectionsBox .con .count .ii{
		font-weight: 400;
		font-size: 14px;
		color: rgb(52, 199, 123);
	}
	.mainCollectionsBox .con .count .tt{
		color: rgb(112, 122, 131);
		font-size:14px;
		font-weight:700;
	}
	.mainCollectionsBox .con .user .img{
		width:50px;
		display: flex;
		margin-right: 8px;
		border-width: 1px;
		border-style: solid;
		border-image: initial;
		border-color: rgb(229, 232, 235);
		border-radius: 50%
	}
	.mainCollectionsBox .con .user .img img{
		width:50px;
		height:50px;
		border-radius:50px;
	}
	.mainCollectionsBox .con .user .verified{
		position: absolute;
		bottom:-4px;
		right:5px;
	}
	.mainCollectionsBox .con .user .new{
		position: absolute;
		top:-2px;
		right:5px;
	}
	.mainCollectionsBox .con .user .verified img{
		width:15px;
		height:15px;
	}
	.mainCollectionsBox .con .user .new .circle{
		width:8px;
		height:8px;
		border:1px solid rgb(229, 232, 235);
		 background-color: rgb(52, 199, 123);
		 border-radius: 50%;
	}
	.el-dropdown-link{
		font-size:24px;
		color:rgb(32, 129, 226);
		margin-left:8px;
	}
	.mainBoxTop{
		width:100%;
		height:500px;
	}
	.mainBoxTop img{
		width:100%;
		height:500px;
		object-fit: fill;
	}
	.mainContainer{
		width: 100%;
		position: absolute;
		overflow: hidden;
	}
	.mainContainerBg{
		height: 586px;
		background-size:cover;
		background-color: rgb(255, 255, 255);
		opacity: 0.3;
		filter: blur(8px);
		background-position: center center;
		-webkit-mask: linear-gradient(rgb(255, 255, 255), transparent);
	}
	.mainBoxTopCon{
		max-width: min(1280px, 100% - 40px);
		width: 100%;
		flex-wrap: wrap;
		margin: 0px auto;
		position: absolute;
		left:50%;
		margin-left:-640px;
		display: flex;
	}
	.topLeft{
		width: 50%;
		padding: 110px 20px 44px 30px;
		flex-direction: column;
		-webkit-box-align: center;
		box-sizing: border-box;
		flex: 1;
	}
	.topLeft .title{
		font-size:45px;
		z-index: 2;
		text-align: left !important;;
		color:#000 !important;
	}
	.topLeft .fontText{
		font-size: 24px;
		text-align: left;
		margin-top: 20px;
		max-width: 400px;
		line-height:35px;
	}
	.topLeftBtn{
		margin-top:40px;
	}
	.topLeftBtn .el-button{
		width:167px;
		height:50px;
		border-radius:10px;
		font-size: 16px;
		font-weight: 600;
	}
	.topLeftBtn .default{
		border-color:rgb(32, 129, 226);
		color:rgb(32, 129, 226);
	}
	.learnMore{
		margin-top:40px;
		color:rgb(32, 129, 226);
		font-size: 20px;
		font-weight:400;
	}
	.learnMore .el-icon{
		vertical-align: bottom;
		font-size:24px;
	}
	.topRight{
		width: 50%;
		padding: 40px 0px;
		flex-direction: column;
		-webkit-box-align: center;
		box-sizing: border-box;
		flex: 1;
	}
	.topRightImg{
		max-width: 550px;
		margin-right: 30px;
		background-color: rgb(251, 253, 255);
		flex-direction: column;
		box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 10px 0px;
		border-radius: 10px;
	}
	.topRightImgBox{
		border-radius: 10px;
		color: rgb(32, 129, 226);
		text-decoration: none;
	}
	.topRightImgBox:hover{
		animation: animated-border 0.5s infinite;
		animation-iteration-count:1;
		animation-fill-mode:forwards;
	}
	@keyframes animated-border {
		0% {
			box-shadow: 0 0 0 0 #bfbfbf;
		}
		100% {
			box-shadow: 0px 1px 23px 10px #cecece;
		}
	}
	.topRightImgBox .Img{
		width:550px;
		height:420px;
		border-radius: 10px;
	}
	.topRightImgBox .Img img{
		object-fit: cover;
		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;
		border-top-left-radius: inherit;
		border-top-right-radius: inherit;
	}
	.ImgFooter{
		width: 100%;
		font-weight: 600;
		color: rgb(53, 56, 64);
		text-align: left;
		padding: 16px;
		display: flex;
		box-sizing: border-box;
	}
	.ImgFooter .user{
		height:40px;
		align-self: center;
		order: 2;
		margin-right: 16px;
		flex-shrink: 0;
		overflow: hidden;
		border-width: 1px;
		border-style: solid;
		border-color: rgb(229, 232, 235);
		border-image: initial;
		border-radius: 50%;
		padding: 0px;
	}
	.ImgFooter .user img{
		width: 40px;
		height: 40px;
		object-fit: cover;
	}
	.ImgFooter .text{
		align-self: stretch;
		-webkit-box-pack: center;
		justify-content: center;
		margin-right: 16px;
		order: 3;
		font-size: 16px;
		align-items: flex-start;
		flex: 1 1 auto;
		flex-flow: column;
		overflow: hidden;
		line-height: 20px;
	}
	.ImgFooter .name{
		font-weight: 600;
		font-size: 14px;
		color: rgb(4, 17, 29);
	}
	.ImgFooter .con{
		color: rgb(32, 129, 226);
		font-weight: 500;
		font-size: 14px;
	}
	.ImgFooter .icon{
		align-self: stretch;
		display: flex;
		-webkit-box-pack: center;
		justify-content: center;
		max-width: 40%;
		order: 4;
		text-align: right;
		flex: 0 0 auto;
		flex-flow: column;
		overflow: hidden;
	}
</style>
