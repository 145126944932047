<template>
  <div class="homePageTop">
    <div class="box">
      <div class="navbarLeft" @click="goHome">
<!--        <img class="logo" src="../assets/img/logoTop.png">-->
<!--        <img class="logo" :src="require('@/assets/img/logoTop.png')">-->
        <img class="logo" src="/img/logoTop.png">
      </div>
    </div>
    <div class="box" style="width:50%;">
      <div class="searchInput">
        <el-input
            style="max-width: 768px;"
            v-model="searchVal"
            class="w-50 m-2"
            size="large"
            placeholder="搜索"
        >
          <template #prefix>
            <el-icon color="" class="el-input__icon">
              <Search/>
            </el-icon>
          </template>
        </el-input>
      </div>
    </div>
    <div class="box">
      <div class="navbarRight" style="width:460px;float:right;">
        <el-menu
            router
            default-active="1"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
        >
          <!--					<el-sub-menu class="subMenu" index="1">-->
          <!--						<template #title>-->
          <!--							<router-link class="routerLink" to="/Explore/Explore/123">-->
          <!--								市场-->
          <!--							</router-link>-->
          <!--						</template>-->
          <!--					</el-sub-menu>-->
          <!--					<el-sub-menu class="subMenu" index="2">-->
          <!--						<template #title>-->
          <!--							<router-link class="routerLink" to="/Stats/Rankings">-->
          <!--								排名-->
          <!--							</router-link>-->
          <!--						</template>-->
          <!--					</el-sub-menu>-->
          <!--					<el-menu-item index="3" @click="MessageBtn">-->
          <!--						消息<font color="red" v-if="urId != undefined || urId != null">({{msg}})</font>-->
          <!--					</el-menu-item>-->
          <!--					<el-menu-item index="4" @click="creatBtn">创建</el-menu-item>-->
          <el-menu-item index="">
            市场
          </el-menu-item>
          <el-menu-item index="">
            排名
          </el-menu-item>
          <el-menu-item index="about">
            关于我们
          </el-menu-item>
<!--          <el-menu-item index="4">-->
<!--            创建-->
<!--          </el-menu-item>-->

          <el-sub-menu class="subMenu" index="">
            <template #title>
              <el-avatar @click="userBtn" v-if="urHead == undefined || urHead == null" :size="32" :src="avantaUrl"/>
              <el-avatar @click="userBtn" v-else :size="32" :src="urHead"/>
            </template>
            <el-menu-item v-if="urId != null" class="menuItem" index="2-1">
              <router-link class="routerLink" to="/Main/User">
                <el-icon :size="18">
                  <User/>
                </el-icon>
                个人中心
              </router-link>
            </el-menu-item>
            <el-menu-item v-if="urId != null" class="menuItem" index="2-2" @click="settingBtn">
              <el-icon :size="18">
                <Setting/>
              </el-icon>
              设置
            </el-menu-item>
            <el-menu-item v-if="urId != null" class="menuItem" index="2-3" @click="exitBtn">
              <el-icon :size="18">
                <TurnOff/>
              </el-icon>
              退出登录
            </el-menu-item>
          </el-sub-menu>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
import avantaUrl from "../assets/img/user.png"
import logoTop from "../assets/img/logoTop.png"
import {Search, Wallet, User, Setting, TurnOff} from '@element-plus/icons-vue'

export default {
  inject: ["reload"],
  components: {Search, Wallet, User, Setting, TurnOff},
  data() {
    return {
      avantaUrl: avantaUrl,
      urHead: window.localStorage.getItem("urHead"),
      searchVal: '',
      urId: '',
      activeIndex: "1",
      msg: 0,
      logoTop:logoTop
    }
  },
  // mounted() {
  //
  // },
  created() {
    let that = this;
    let urId = window.localStorage.getItem("urId");
    this.urId = urId;
    if (urId != undefined || urId != null) {
      this.getTotalMessage(urId);
    }
  },
  methods: {
    getTotalMessage(urId) {
      let that = this;
      this.$axios({
        method: 'post',
        url: 'user/me/getCount',
        headers: {
          token: window.localStorage.getItem("token")
        },
        data: this.$qs.stringify({
          urId: urId
        })
      })
          .then((res) => {
            that.msg = res.data.msg;
          })
          .catch((err) => console.log('请求失败', err))
    },
    MessageBtn() {
      let that = this;
      let urId = window.localStorage.getItem("urId");
      if (urId == undefined || urId == null || urId == '') {
        this.$router.push({
          name: "Login"
        })
      } else {
        this.$router.push({
          name: "Message"
        })
      }
    },
    creatBtn() {
      let that = this;
      let urId = window.localStorage.getItem("urId");
      if (urId == undefined || urId == null || urId == '') {
        this.$router.push({
          name: "Login"
        })
      } else {
        that.checkCreate();
      }
    },
    checkCreate() {
      let that = this;
      this.$axios({
        method: 'post',
        headers: {
          token: window.localStorage.getItem("token")
        },
        url: 'user/ur/checkCreate',
        data: this.$qs.stringify({
          urId: window.localStorage.getItem("urId")
        })
      })
          .then((res) => {
            let payPass = res.data.payPass;
            if (payPass == "0") {
              this.$confirm('您当前未设置支付密码，请设置支付密码!', '提示', {
                confirmButtonText: '去设置',
                cancelButtonText: '取消',
                type: 'warning'
              })
                  .then(() => {
                    that.$router.push({
                      name: 'Setting.Secure.PayPass'
                    })
                  })
                  .catch(() => {
                  });
            } else {
              let pnId = '123';
              let urFuel = res.data.urFuel;
              if (urFuel > 0) {
                that.$router.push({
                  path: '/Create/' + pnId,
                })
              } else {
                this.$confirm('您当前的燃料数量为0，请充值!', '提示', {
                  confirmButtonText: '去充值',
                  cancelButtonText: '取消',
                  type: 'warning'
                })
                    .then(() => {

                    })
                    .catch(() => {
                    });
              }
            }
          })
          .catch((err) => console.log('请求失败', err))
    },
    userBtn() {
      // let urId = window.localStorage.getItem("urId");
      // if (urId == undefined || urId == null || urId == '') {
      //   this.$router.push({
      //     name: "Login"
      //   })
      // } else {
      //   this.$router.push({
      //     name: "Main.User"
      //   })
      // }
    },
    goHome() {
      this.$router.push({
        name: "Home"
      })
    },
    settingBtn() {
      window.localStorage.setItem("selectMenu", "/Setting/Basic");
      window.localStorage.setItem("selectName", "基本信息");
      this.$router.push({
        name: "Setting.Basic"
      })
    },
    exitBtn() {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("urId");
      window.localStorage.removeItem("urName");
      window.localStorage.removeItem("urHead");
      window.localStorage.removeItem("selectMenu");
      window.localStorage.removeItem("selectName");
      this.$router.push({
        name: "Home"
      })
    },
  },
  mounted() {

  },
}
</script>

<style scoped>
.homePageTop {
  width: 100%;
  height: 72px;
  background: #fff;
  box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
  position: sticky;
  top: 0px;
  z-index: 110;
  transition: top 0.5s ease 0s;
}

.homePageTop .box {
  height: 72px;
  display: inline-block;
  vertical-align: text-top;
}

.homePageTop .box:first-child {
  width: 25%;
}

.homePageTop .box:last-child {
  width: 25%;
}

.el-row {
  height: 72px;
}

.navbarLeft {
  padding-left: 15px;
}

.navbarLeft .logo {
  height: 40px;
  margin-top: 16px;
}

.searchInput {
  height: 40px;
  margin-top: 16px;
}

.navbarRight, .el-menu {
  height: 72px;
}

.el-menu--horizontal {
  border-bottom: none;
}

.el-sub-menu__title {
  justify-content: center;
}

.subMenu .el-icon {
  margin-left: 0px;
}

.el-menu-item, .el-sub-menu__title {
  width: 85px;
}
</style>
