(function() {
  window.accurateInterval = function(time, fn) {
    var cancel, nextAt, timeout, wrapper, _ref;
    nextAt = new Date().getTime() + time;
    timeout = null;
    if (typeof time === 'function') _ref = [time, fn], fn = _ref[0], time = _ref[1];
    wrapper = function() {
      nextAt += time;
      timeout = setTimeout(wrapper, nextAt - new Date().getTime());
      return fn();
    };
    cancel = function() {
      return clearTimeout(timeout);
    };
    timeout = setTimeout(wrapper, nextAt - new Date().getTime());
    return {
      cancel: cancel
    };
  };
}).call(this);
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import VueUeditorWrap from 'vue-ueditor-wrap'
import Axios from 'axios'
import qs from 'qs'
import 'element-plus/dist/index.css'
import '@/assets/css/panel.css'
import '@/assets/css/style.css'
import App from './App.vue'
import router from './router'
import VueClipboard from 'vue-clipboard2'
import { ElMessage, ElMessageBox } from 'element-plus'
const app = createApp(App)
// const location = window.location.origin + '/api/'
// const location = 'http://art.klwcc.cc:88/api/'
const location = window.location.origin + '/api/';
let isBreak = true;
let token = window.localStorage.getItem("token")
Axios.defaults.baseURL = location
Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
Axios.interceptors.request.use(config => {//http请求拦截器
  return config
}, error => {
  return Promise.reject(error)
})
Axios.interceptors.response.use(response => {//http响应拦截器
	let code = response.data.code;
	if(isBreak){
		isBreak = false;
		if(code == 1){//登录已失效，请重新登录
			ElMessageBox.alert("登录已失效，请重新登录", "错误提示",{
				confirmButtonText: '确定',
				callback:()=>{
					window.localStorage.removeItem("urId");
					window.localStorage.removeItem("urName");
					window.localStorage.removeItem("selectMenu");
					window.localStorage.removeItem("urHead");
					window.localStorage.removeItem("selectName");
					window.localStorage.removeItem("token");
					router.replace({
						name: 'Login'
					})
				},
				showClose:false
			})
		}
		if(code == 2){//请求错误，请稍后重试
			ElMessageBox.alert("请求错误，请稍后重试", "错误提示",{
				confirmButtonText: '确定',
				callback:()=>{
					// router.replace({
					// 	name: 'Login'
					// })
				},
				showClose:false
			})
		}
	}
  return response
}, error => {
  let status = error.request.status;
  if(status == 502){
    // router.replace({
    //   name: 'About'
    // })
  }
  return Promise.reject(error)
})
app.config.globalProperties.$axios = Axios
app.config.globalProperties.$qs = qs
app.config.globalProperties.$uploadUrl = location
app
	.use(ElementPlus,{locale: zhCn,})
	.use(VueUeditorWrap)
	.use(router)
	.use(VueClipboard)
	.mount('#app')
