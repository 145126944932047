<template>
    <div class="card-carousel-wrapper" v-if="type=='0'">
    	<div class="card-carousel--nav__left" @click="moveCarousel(-1)"></div>
    	<div class="card-carousel">
    		<div class="card-carousel--overflow-container">
    			<div class="card-carousel-cards" :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')'}">
    				<div class="card-carousel--card" v-for="item in items" :key="item.cnId">
    					<div class="card-carousel-box">
    						<img :src="item.cnFeatured">
    						<div class="circle">
    							<img :src="item.cnLogo">
    						</div>
    					</div>
    					<div class="card-carousel--card--footer">
    						<p>
    							{{item.cnName}}
    						</p>
    						<p style="font-size:14px;">
    							{{item.urName}}
    						</p>
    						<div class="con">{{item.cnDes}}</div>
    					</div>
    				</div>
    			</div>
    		</div>
    	</div>
    	<div class="card-carousel--nav__right" @click="moveCarousel(1)"></div>
    </div>
	
	<div class="card-carousel-wrapper" v-if="type=='1'">
		<div class="card-carousel--nav__left" @click="moveCarousel(-1)"></div>
		<div class="card-carousel">
			<div class="card-carousel--overflow-container">
				<div class="card-carousel-cards" :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')'}">
					<div class="card-carousel--card" style="height:600px;border:none;" v-for="item in items" :key="item.cnId">
						<div class="card-carousel-box" style="height:600px;position:relative;">
							<img style="height:600px;border-radius: 10px;" :src="item.pnImage">
							<div class="box">
								<div class="title">{{item.pnName}}</div>
								<div class="des">{{item.pnDes}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card-carousel--nav__right" @click="moveCarousel(1)"></div>
	</div>
</template>
<script>
export default {
	props:['cteId','type'],
	data() {
		return{
			currentOffset: 0,
			windowSize: 3,
			paginationFactor: 412,
			items: []
		}
	},
	created(){
		this.getListCategory("");
	},
	watch:{
		cteId(newVal,oldVal){
			this.getListCategory(newVal);
		}
	},
	computed: {
	  atEndOfList() {
	    return this.currentOffset <= (this.paginationFactor * -1) * (this.items.length - this.windowSize);
	  },
	  atHeadOfList() {
	    return this.currentOffset === 0;
	  },
	},
	methods: {
		DetailConBtn(pnId,pnType){
			let urId = window.localStorage.getItem("urId");
			if(urId == undefined || null){
				urId = "";
			}
			if(pnType == '0' || pnType == "1"){
				this.$router.push({
					path:'/Content/Content/'+ pnId +'/'+ pnType,
				})
			}
		},
		detailBtn(cnId){
			this.$router.push({
				path:'/Explore/Collection/'+ cnId,
			})
		},
		getListCategory(cteId){
			let that = this;
			let type = that.type;
			let url = 'stats/ho/getHots';
			if(type == "1"){
				url = "nft/pn/getHots";
			}
			this.$axios({
				method:'post',
				url:url,
				headers: {
					token:""
				},
				data:this.$qs.stringify({
					cteId: cteId,
					page: 1,
					limit: 12
				})
			})
			.then((res)=>{
				that.items = res.data.dataRoot;
			})
			.catch((err)=>console.log('',err))
		},
		moveCarousel(direction) {
			let totalCount = (this.paginationFactor * -1) * (this.items.length - this.windowSize);
			if(direction === 1) {
				if(this.currentOffset <= totalCount){			  
					this.currentOffset = 0;
				}else{
					this.currentOffset -= this.paginationFactor;
				}
	      
			}else if (direction === -1 && !this.atHeadOfList) {
				this.currentOffset += this.paginationFactor;
			}
		},
	}
};
</script>
<style scoped>
	.card-carousel-box .box{
		width:100%;
		position: absolute;
		bottom:10px;
		padding:10px;
		box-sizing: border-box;
	}
	.card-carousel-box .title{
		font-size:20px;
		font-weight:600;
		color:#fff;
	}
	.des{
		font-size:14px;
		font-weight:400;
		color:#fff;
		margin-top:10px;
		line-height:25px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp:2;
		-webkit-box-orient: vertical;
	}
	.card-carousel-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 20px 0 40px;
		color: #666a73;
	}
	.card-carousel {
		display: flex;
		justify-content: center;
		width: 1209px;
	}
	.card-carousel--overflow-container {
	  overflow: hidden;
	}
	.card-carousel--nav__left, .card-carousel--nav__right {
		display: inline-block;
		width: 15px;
		height: 15px;
		padding: 10px;
		box-sizing: border-box;
		border-top: 2px solid black;
		border-right: 2px solid black;
		opacity: 0.2;
		cursor: pointer;
		margin: 0 10px;
		transition: transform 150ms linear;
	}
	.card-carousel--nav__left {
		transform: rotate(-135deg);
	}
	.card-carousel--nav__left:active {
		transform: rotate(-135deg) scale(0.9);
	}
	.card-carousel--nav__right {
		transform: rotate(45deg);
	}
	.card-carousel--nav__right:active {
		transform: rotate(45deg) scale(0.9);
	}
	.card-carousel-cards {
		display: flex;
		transition: transform 150ms ease-out;
		transform: translatex(0px);
	}
	.card-carousel-cards .card-carousel--card {
		height:400px;
		margin: 0 10px;
		cursor: pointer;
		box-shadow: 0 4px 15px 0 rgba(40, 44, 53, 0.06), 0 2px 2px 0 rgba(40, 44, 53, 0.08);
		background-color: #fff;
		border-radius: 4px;
		z-index: 3;
		margin-bottom: 2px;
	}
	.card-carousel-cards .card-carousel--card:hover{
		animation: animated-border 0.5s infinite;
		animation-iteration-count:1;
		animation-fill-mode:forwards;
	}
	@keyframes animated-border {
		0% {
			box-shadow: 0 0 0 0 #bfbfbf;
		}
		100% {
			box-shadow: 0px 1px 5px 5px #cecece;
		}
	}
	.card-carousel-box{
		width:392px;
		height:225px;
		position: relative;
	}
	.card-carousel--card:first-child {
		margin-left: 0;
	}
	.card-carousel--card:last-child {
		margin-right: 0;
	}
	.card-carousel--card img {
		width:392px;
		height:200px;
		vertical-align: bottom;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		transition: opacity 150ms linear;
		user-select: none;
	}
	.card-carousel--card--footer {
		border-top: 0;
		padding:10px;
	}
	.card-carousel--card--footer img{
		width:15px;
		height:15px;
		vertical-align:middle;
	}
	.circle{
		width:46px;
		height:46px;
		border:1px solid rgb(251, 253, 255);
		background:rgb(251, 253, 255);
		border-radius:50%;
		padding:2px;
		display:flex; 
		justify-content:center; 
		align-items:center; 
		box-shadow: rgba(14, 14, 14, 0.6) 0px 0px 2px 0px;
		position: absolute;
		bottom:0px;
		left:50% !important;
	}
	.circle img{
		width:44px !important;
		height:44px !important;
		border-radius:50% !important;
	}
	.card-carousel--card--footer p {
		width:225px;
		padding: 3px 0;
		text-align: center;
		margin: 0px auto;
		margin-bottom: 2px;
		font-size: 16px;
		font-weight: 500;
		color: #2c3e50;
		font-weight:bold;
		user-select: none;
	}
	.card-carousel--card--footer .con{
		width:80%;
		margin:20px auto;
		text-align: center;
		line-height: 20px;
		font-weight: 400;
		font-size: 16px;
		color: rgb(112, 122, 131);
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
	.card-carousel-cards .card-carousel--card--footer p:nth-of-type(2):before {
		content: "";
		float: left;
		position: absolute;
		top: 0;
		left: -12px;
		width: 0;
		height: 0;
		border-color: transparent rgba(40, 44, 53, 0.06) transparent transparent;
		border-style: solid;
		border-width: 12px 12px 12px 0;
	}
	.card-carousel-cards .card-carousel--card--footer p:nth-of-type(2):after {
		content: "";
		position: absolute;
		top: 10px;
		left: -1px;
		float: left;
		width: 4px;
		height: 4px;
		border-radius: 2px;
		background: white;
		box-shadow: -0px -0px 0px #004977;
	}
	h1 {
		font-size: 3.6em;
		font-weight: 100;
		text-align: center;
		margin-bottom: 0;
		color: #42b883;
	}
</style>